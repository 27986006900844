.ProjetosSecao{
    background-image: linear-gradient(#000000, #0F0F0F);
    color: white;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
}

.ProjetosTituloBox{
    grid-column: 6/span 6;
    text-align: end;
    margin: 200px 0;
}
.ProjetosTitulo{
    font-size: 6vw;
    margin-bottom: 40px;
}
.ProjetosSubtitulo{
    font-size: 3.5vw;
}

.AreaProjetos{
    grid-column: 2/span 10;
    /* background-color: blue; */
    display: grid;
    grid-template-columns: repeat(10, 1fr);
}
.AreaSelecaoProjetos{
    grid-column: 1/span 3;
    /* background-color: red; */
    height: 50vw;
    overflow-y: scroll;
}
.AreaSelecaoProjetos::-webkit-scrollbar{
    background-color: rgba(0, 0, 0, 0);
    width: 0.8vw;
}
.AreaSelecaoProjetos::-webkit-scrollbar-thumb{
    background-color: rgba(255, 255, 255, 0.05);
    border-radius: 2vw;
}
.BotaoSelecaoProjeto{
    background-color: rgb(34, 34, 34) !important;
    /* width: 65%; */
    /* height: 17%; */
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 40px auto;
    cursor: pointer;
    transition: width 0.5s, height 0.5s;
}
.ProjetoAndamentoIcone{
    font-size: 3vw;
    /* rotate: -90deg; */
}
.Projeto{
    grid-column: 6/span 5;
    /* background-color: gold; */
    height: fit-content;
}
.Projeto *{
    margin: auto;
}
.ProjetoImagemNaoFinalizado{
    background-color: rgb(34, 34, 34) !important;
    /* width: 85%; */
    width: 35vw;
    /* height: 35%; */
    height: 17vw;
    transition: background-color 1s;
    display: flex;
    justify-content: center;
    align-items: center;
}
.ProjetoTitulo{
    /* background-color: red; */
    width: fit-content;
    /* margin: 30px auto; */
    margin: 5vw auto;
    font-size: 2.3vw;
    transition: color 1s;
}
.ProjetoDescricao{
    /* background-color: blue; */
    /* width: 85%; */
    width: 35vw;
    /* height: 30%; */
    height: 15vw;
    margin-bottom: 30px;
    font-size: 1.5vw;
    text-align: center;
    transition: color 1s;
}
.ProjetoTecnologias{
    /* background-color: green; */
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    /* margin-bottom: 500px; */
    /* width: 100%; */
}
.FadeOut *{
    background-color: white;
    color: black;
}
.FadeIn *{
    background-color: rgba(0, 0, 0, 0);
    color: rgba(0, 0, 0, 0);
}
.ProjetoTecnologia{
    font-size: 1vw;
    padding: 0.8vw 2vw;
    border-radius: 2vw;
    margin: 1.5vw;
    transition: background-color 1s, color 1s;
}

.AreaBotoes{
    grid-column: 3/span 6;
    margin: 200px 0;
    /* background-color: red; */
}
.ProjetoBotao{
    /* background-color: red; */
    display: block;
    margin: auto;
    margin-bottom: 120px;
    text-align: center;
    text-decoration: none;
    padding: 2vw;
    font-size: 2.7vw;
    border-radius: 2vw;
    color: white;
}
.BotaoVerProjeto{
    border: 3px solid white;
}
.BotaoAcessarProjeto{
    background-color: #921818;
}
.BotaoVerProjeto:hover{
    background-color: white;
    color: black;
    transition-duration: 0.1s;
}
.BotaoAcessarProjeto:hover{
    background-color: #c82222;
    transition-duration: 0.1s;
}

@media (max-width: 768px) {
    .ProjetosTituloBox{
        margin-top: 0;
        margin-bottom: 20vw;
        grid-column: 5/span 7;
    }
    .ProjetosTitulo{
        font-size: 8vw;
        margin-bottom: 2vw;
    }
    .ProjetosSubtitulo{
        font-size: 5vw;
    }

    .AreaSelecaoProjetos{
        /* background-color: red; */
        grid-column: 1/span 10;
        display: flex;
        overflow: hidden;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        height: fit-content;
        padding: 3vw 0;
    }
    .BotaoSelecaoProjeto{
        margin: 2vw 1.7vw;
    }

    .Projeto{
        grid-column: 2/span 8;
        /* height: 90vw; */
        margin-top: 15vw;
    }
    .ProjetoImagemNaoFinalizado{
        width: 100%;
        height: 37vw;
    }
    .ProjetoTitulo{
        font-size: 6vw;
        margin: 15vw auto;
    }
    .ProjetoDescricao{
        font-size: 4vw;
        margin: 0 0 10vw 0;
        width: 100%;
        height: 30vw;
    }

    .ProjetoTecnologia{
        font-size: 1.9vw;
        padding: 1.3vw 3.2vw;
        margin: 2vw;
        border-radius: 2.5vw;
    }

    .AreaBotoes{
        grid-column: 2/span 8;
        margin: 20vw 0;
    }
    .ProjetoBotao{
        margin-bottom: 15vw;
        font-size: 4.5vw;
        padding: 2.5vw;
        border-radius: 3vw;
    }
    .BotaoVerProjeto{
        border-width: 3px;
        
    }
}