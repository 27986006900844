.ContatoSecao{
    background-image: linear-gradient(#F9F9F9, #F5F5F5);
    display: grid;
    grid-template-columns: repeat(12, 1fr);
}

.ContatoTituloBox{
    grid-column: 2/span 6;
    margin: 200px 0;
}
.ContatoTitulo{
    font-size: 6vw;
    margin-bottom: 40px;
}
.ContatoSubtitulo{
    font-size: 3.5vw;
}

.AreaContato{
    grid-column: 2/span 10;
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    margin-bottom: 200px;
}

.ContatoFormulario{
    /* background-color: red; */
    background-color: white;
    box-shadow: 2px 5px 10px 5px rgba(0, 0, 0, 0.1);
    grid-column: 1/span 4;
    border-radius: 0.7vw;
}

.ContatoFormulario *{
    font-size: 1.5vw;
}
.BotaoEnviarContato{
    /* padding: 10vw; */
}

.ContatoFormularioLabel{
    /* background-color: red; */
    width: fit-content;
}
.BotaoEnviarContato{
    all: unset;
}
.ContatoFormularioInputNome, 
.ContatoFormularioInputTel,
.ContatoFormularioInputEmail,
.ContatoFormularioInputMsg,
.BotaoEnviarContato{
    padding: 3% 0; 
    border-radius: 0.4vw;
    border: none;
}
.ContatoFormularioInputNome, 
.ContatoFormularioInputTel,
.ContatoFormularioInputEmail,
.ContatoFormularioInputMsg{
    padding-left: 5%;
    background-color: #F3F3F3;
}
.ContatoFormularioInputNome:focus, 
.ContatoFormularioInputTel:focus,
.ContatoFormularioInputEmail:focus,
.ContatoFormularioInputMsg:focus{
    background-color: #D9D9D9;
    /* border: none !important; */
    outline: none;
}

.ContatoFormularioInputNome{
    width: 74%;
    display: block;
    margin: auto;
    margin-top: 70px;
}
.ContatoFormularioEmailTelBox{
    width: 80%;
    margin: auto;
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
}
.ContatoFormularioInputTel, .ContatoFormularioInputEmail{
    width: 42%;
    /* background-color: blue; */
    display: block;
}
.ContatoFormularioInputMsg{
    display: block;
    width: 75%;
    margin: auto;
    margin-top: 40px;
    padding-top: 4%;
}
.BotaoEnviarContato{
    /* color: blue; */
    font-size: 1.5vw;

    display: block;
    background-color: #FF756C;
    color: white;
    margin: auto;
    margin-top: 70px;
    margin-bottom: 100px;
    width: 80%;
    text-align: center;
    cursor: pointer;
}

.ContatoAreaInformacoes{
    /* background-color: blue; */
    grid-column: 7/span 4;
}
.ContatoInformacoesTexto{
    /* background-color: red; */
    text-align: center;
    font-size: 2.2vw;
}
.ContatoInformacoesIconeBox{
    background-color: #D9D9D9;
    width: fit-content;
    margin: 60px auto;
    padding: 2vw;
    border-radius: 10vw;
}
.ContatoInformacoesIcone{
    font-size: 5vw !important;
    color: white;
}
.ContatoInformacao{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.25);
    color: white;
    margin: 50px auto;
    width: 70%;
    padding: 1.2vw 2vw;
    border-radius: 1vw;
}
.ContatoInformacaoIconTextoBox{
    display: flex;
    align-items: center;
    /* background-color: red; */
}
.ContatoInformacaoIcone{
    font-size: 1.7vw !important;
    margin-top: 2%;
    /* background-color: chocolate; */
}
.ContatoInformacaoTexto{
    /* background-color: aqua; */
    font-size: 1.1vw;
    margin-left: 1.4vw;
}
.ContatoInformacaoBotaoCopiarBox{
    cursor: pointer;
}
.ContatoInformacaoBotaoCopiar{
    font-size: 1.6vw !important;
}
.ContatoInformacaoBotaoCopiar:hover{
    color: rgb(82, 82, 82);
}
.MsgCopiado{
    background-color: rgb(30, 30, 30);
    color: white;
    font-size: 0.9vw;
    padding: 0.5vw 1vw;
    border-radius: 0.5vw;
    position: absolute;
    margin-left: -1.45vw;
}   

@media (max-width: 768px){
    .ContatoTituloBox{
        margin-top: 0;
        margin-bottom: 20vw; 
        grid-column: 2/span 7;
    }
    .ContatoTitulo{
        font-size: 8vw;
        margin-bottom: 2vw;
    }
    .ContatoSubtitulo{
        font-size: 5vw;
    }

    .ContatoFormulario{
        grid-column: 1/span 10;
    }
    .ContatoFormulario *{
        font-size: 3vw;
    }
    .ContatoFormularioEmailTelBox, .ContatoFormularioInputMsg{
        margin-top: 8vw;
    }
    .BotaoEnviarContato{
        font-size: 5vw;
    }

    .ContatoAreaInformacoes{
        grid-column: 1/span 10;
        /* background-color: red; */
        margin-top: 20vw;
    }
    .ContatoInformacoesTexto{
        font-size: 5vw;
    }
    .ContatoInformacoesIconeBox{
        padding: 5vw;
        border-radius: 50vw;
        margin: 20vw auto;
    }
    .ContatoInformacoesIcone{
        font-size: 12vw !important;
    }
    .ContatoInformacao{
        padding: 2.5vw 4vw;
        border-radius: 2vw;
        margin: 10vw auto;
    }
    .ContatoInformacaoIcone{
        font-size: 4vw !important;
    }
    .ContatoInformacaoTexto{
        font-size: 3vw !important;
        margin-left: 2.5vw;
    }
    .ContatoInformacaoBotaoCopiar{
        font-size: 4vw !important;
    }
}