.Header{
    background-image: linear-gradient(#0F0F0F, #000000);
    color: white;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
}

.ApresentacaoBox{
    grid-column: 1/span 12;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
}

.MinhaImagemApresentacao{
    background-image: url('https://meu-site-eat6.onrender.com/img/MinhaImagem1.jpg');
    background-size: 15vw;
    width: 15vw;
    height: 15vw;
    margin: auto;
    grid-column: 6/span 2;
    margin-top: 200px;
    margin-bottom: 50px;
    border: solid 3px #921818;
    border-radius: 10vw;
}

.TextoApresentacao{
    grid-column: 2/span 10;
    font-size: 5vw;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 270px;
}

.ApresentacaoTextoVermelho{
    color: #F03737;
}

@media (max-width: 768px) {
    .MinhaImagemApresentacao{
        background-size: 30vw;
        width: 30vw;
        height: 30vw;
        grid-column: 5/span 4;
        margin-top: 100px;
        margin-bottom: 25px;
        border-radius: 20vw;
    }
    .TextoApresentacao{
        font-size: 7vw;
    }
}

@media (max-width: 425px) {
    .MinhaImagemApresentacao{
        margin-top: 50px;
        margin-bottom: 10px;
    }
    .TextoApresentacao{
    }
}