footer{
    background-color: black;
    color: white;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    padding-bottom: 100px;
}

.FooterColuna{
    /* background-color: red; */
    padding: 3vw 0;
}
.FooterColunaDesenvolvedor{
    grid-column: 2/span 5;
}
.FooterColunaTecnologiasUsadas{
    grid-column: 7/span 5;
}
.FooterTitulo{
    margin: 1.3vw 0;
    font-size: 2.5vw;
    font-weight: 700;
}
.FooterConteudo{
    font-size: 1.5vw;
    padding: 1vw 0;
    display: flex;
    align-items: center;
    /* background-color: blue; */
}
.FooterTecnologiaUsada{
    margin-left: 0.5vw;
}
.FooterColunaRepositorio{
    grid-column: 2/span 5;
}
.FooterLinkRepositorio{
    color: white;
    text-decoration: none;
}
.FooterLinkRepositorio:hover{
    text-decoration: underline;
}
.FooterLinkRepositorioIcone{
    font-size: 1vw !important;
    margin-left: 0.5vw;
}

@media (max-width: 768px) {
    footer{
        padding-top: 4vw;
    }

    .FooterTitulo{
        font-size: 4vw;
    }
    .FooterConteudo{
        font-size: 3vw;
    }
    .FooterTecnologiaUsada{
        margin-left: 3vw;
    }
    .FooterLinkRepositorio{
        font-size: 2vw;
    }
}