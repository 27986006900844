.SobreSecao{
    background-image: linear-gradient(#F9F9F9, #F5F5F5);
    display: grid;
    grid-template-columns: repeat(12, 1fr);
}

.MinhaImagemSobre{
    background-image: url('https://meu-site-eat6.onrender.com/img/MinhaImagem2.jpg');
    background-size: 120% 100%;
    background-position: center;
    grid-column: 1/ span 5;
    grid-row: 1/span 10;
    height: 90vw;
}

.SobreConteudoBox{
    grid-column: 7/span 5;
    grid-row: 5;
    /* background-color: red; */
}
.SobreIconeBox{
    /* background-color: red; */
    width: fit-content;
    margin: auto;
}
.SobreIcone{
    font-size: 12vw !important;
}
.SobreTextoBox{
    margin-top: 5vw;
    /* background-color: blue; */
}
.SobreTexto{
    font-size: 3vw;
    text-align: center;
    /* background-color: red; */
}
.SobreBotaoCurriculo{
    /* background-color: red; */
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* text-align: center; */
    font-size: 2.2vw;
    margin-top: 10vw;
    padding: 1.5vw 0;
    border: 3px solid black;
    border-radius: 1vw;
    /* text-decoration: none; */
    text-decoration-color: transparent;
}
.SobreBotaoIconeBox{
    margin-left: 1vw;
}
.SobreBotaoIcone{
    font-size: 2vw !important;
    margin-left: 1vw;
    /* background-color: red; */
}
.SobreBotaoCurriculoTextoPDF{
    color: #F03737;
}

.SobreBotaoCurriculo:hover{
    transition: 0.3s;
    /* text-decoration: underline; */
    text-decoration-color: black;
}

@media (max-width: 768px) {
    .MinhaImagemSobre{
        background-size: 160% 100%;
        background-position-x:  center;
        height: 140vw;
    }
    .SobreConteudoBox{
        grid-row: 3;
    }
    .SobreBotaoIconeBox{
        margin-top: 0;
    }
    .SobreIcone{
        font-size: 15vw !important;
    }
    .SobreTextoBox{
        margin-top: 5vw;
    }
    .SobreTexto{
        font-size: 4vw;
    }
    .SobreBotaoCurriculo{
        font-size: 2.5vw;
        margin-top: 7vw;
        padding: 2.3vw 0;
        border-width: 2px;
        border-radius: 2vw;
    }
    .SobreBotaoIcone{
        font-size: 2.2vw !important;
    }
}
@media (max-width: 425px) {
    .SobreBotaoCurriculo{
        padding: 2vw 0;
        /* border-radius: ; */
    }
}