*{
    margin: 0;
    padding: 0;
}

@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

.raleway-Fonte1 {
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-weight: 900;
  font-style: normal;
}
.raleway-Fonte2 {
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
}
.raleway-Fonte3 {
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}
.raleway-Fonte4 {
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-weight: 200;
  font-style: normal;
}

@import url('https://fonts.googleapis.com/css2?family=Cousine:ital,wght@0,400;0,700;1,400;1,700&display=swap');

.cousine-regular-Fonte1 {
  font-family: "Cousine", monospace;
  font-weight: 200;
  font-style: normal;
}
.cousine-regular-Fonte2 {
  font-family: "Cousine", monospace;
  font-weight: 600;
  font-style: normal;
}