.Navbar{
    font-size: 1.4vw;
    margin: 3vw 0;
    /* background-color: red; */
}

.NavEsquerda{
    /* background-color: red; */
    grid-column: 2/span 5;
}
.NavDireita{
    grid-column: 7/span 5;
}

.NavPreto{
    color: rgba(255, 255, 255, 0.8);
}
.NavBranco{
    color: rgba(0, 0, 0, 0.8);
}

.NavbarList{
    display: flex;
    justify-content: space-around;
    list-style-type: none;
}
.NavbarItem{
    cursor: pointer;
    /* color: red; */
}

.NavPreto .NavbarItem:hover{
    color: #FFFFFF;
}
.NavBranco .NavbarItem:hover{
    color: #000000;
}

.NavbarBotaoMenuBox{
    /* margin-top: 10vw; */
    width: fit-content;
}
.NavbarBotaoMenu{
    font-size: 7vw;
    margin-top: 2vw;
}
.NavbarListAberta{
    font-size: 3.5vw;
    padding: 0 1vw;
    list-style-type: none;
    /* background-color: red; */
    overflow: hidden;
    transition: height 1s;
}
.NavbarListAberta li{
    /* background-color: red; */
    margin: 2.5vw 0;
    width: fit-content;
}
.BotaoFecharMenu{
    font-size: 8vw !important;
    margin-left: -1.5vw;
    /* background-color: red; */
}
.BotaoFecharMenuBox{
    width: fit-content;
    /* background-color: blue; */
    margin-left: 0;
}

@media (max-width: 768px) {
    .Navbar{
        /* background-color: red; */
        height: 40vw;
        margin-top: 7vw;
        margin-bottom: 0;
    }
    /* .NavDireita{
        display: flex;
        justify-content: end;
    } */
    .NavDireita .NavbarBotaoMenuBox,
    .NavDireita li{
        margin: 2.5vw auto;
        margin-right: 0;
    }
    .NavDireita .BotaoFecharMenuBox{
        /* background-color: red; */
        margin: auto;
        margin-right: 0;
    }
}