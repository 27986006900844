.TecnologiasSecao{
    background-image: linear-gradient(#0F0F0F, #000000);
    color: white;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
}

.TecnologiasTituloBox{
    grid-column: 2/span 6;
    margin: 200px 0;
}
.TecnologiasTitulo{
    font-size: 6vw;
    margin-bottom: 40px;
}
.TecnologiasSubtitulo{
    font-size: 3.5vw;
}

.AreaTecnologiasCategorias{
    grid-column: 2/span 10;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    /* margin-top: 10px; */
    margin-bottom: 200px;
}

.TecnologiasCategoria{
    /* background-color: blue; */
    width: 32vw;
    margin: 50px 0;
}
.TecnologiasCategoriaTitulo{
    margin: auto;
    font-size: 2.3vw;
    width: fit-content;
}
.TecnologiasCategoriaIconeBox{
    margin: 20px auto;
    width: fit-content;
}
.TecnologiasCategoriaIcone{
    font-size: 4vw !important;
}

.TecnologiasArea{
    transition: height 1s;
    overflow: hidden;
}

.Tecnologia{
    /* background-color: red; */
    display: flex;
    align-items: center;
    padding: 1.5vw;
    /* width: 30vw; */
    font-size: 1.5vw;
}
.TecnologiaIcone{
    /* background-color: red; */
    font-size: 2vw;
}
.TecnologiaNome{
    margin-left: 2vw;
}

.TecnologiasBotaoMostrarMais{
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 1.5vw;
    margin-top: 70px;
    cursor: pointer;
}
.TecnologiasBotaoMostrarMaisIcone{
    /* background-color: red; */
    display: block;
    /* margin: 10px; */
    margin: auto;
    font-size: 4vw !important;
}

@media (max-width: 768px) {
    .TecnologiasTituloBox{
        margin-top: 0;
        margin-bottom: 5vw; 
        grid-column: 2/span 7;
    }
    .TecnologiasTitulo{
        font-size: 8vw;
        margin-bottom: 2vw;
    }
    .TecnologiasSubtitulo{
        font-size: 5vw;
    }

    .AreaTecnologiasCategorias{
        grid-column: 3/span 8;
        /* background-color: red; */
    }
    .TecnologiasArea{
        /* background-color: red; */
    }
    .TecnologiasCategoria{
        /* background-color: blue; */
        width: 100%;
    }
    .TecnologiasCategoriaTitulo{
        font-size: 7vw;
    }
    .TecnologiasCategoriaIcone{
        font-size: 13vw !important;
    }
    
    .Tecnologia{
        padding: 3vw 1.5vw;
    }
    .TecnologiaNome{
        font-size: 5vw;
        margin-left: 3vw;
    }
    .TecnologiaIcone{
        font-size: 6vw !important;
    }

    .TecnologiasBotaoMostrarMais{
        margin-top: 10vw;
        /* height: fit-content; */
        padding: 0.5vw;
    }
    .TecnologiasBotaoMostrarMaisIcone{
        font-size: 7vw !important;
        /* display: block; */
        height: fit-content;
        /* margin: 10vw auto; */
        /* background-color: red; */
    }
}